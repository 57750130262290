import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem to="/products" icon="element-11" title="Product" />
      <AsideMenuItem to="/orders/list" icon="element-11" title="Orders" />
      <AsideMenuItem to="/completed-orders/list" icon="element-11" title="History" />
      <AsideMenuItem to="/expenses/list" icon="element-11" title="Expenses" />
      <AsideMenuItem to="/dashboard" icon="element-11" title="Dashboard" />
      {/* <AsideMenuItem to="/users" icon="element-11" title="User" /> */}
      {window.location.pathname === '/user/change-password' && <AsideMenuItem to="/user" icon="element-11" title="Change Password" />}
      {window.location.pathname === '/profile/update' &&
      <AsideMenuItem to="/profile" icon="element-11" title="Profile" />}
    </>
  );
}
