import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";

const PrivateRoutes = () => {
  const ChangePasswordPage = lazy(
    () => import("../modules/changePassword/ChangePasswordPage")
  );
  const OrderPage = lazy(() => import("../modules/apps/order/OrderPage"));
  const ProductPage = lazy(() => import("../modules/apps/product/ProductPage"));
  const ExpensePage = lazy(() => import("../modules/apps/expense/ExpensePage"));
  const DashboardPage = lazy(
    () => import("../modules/apps/dashboard/DashboardPage")
  );

  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path="auth/*" element={<Navigate to="/users" />} />

          <Route
            path="orders/*"
            element={
              <SuspensedView>
                <OrderPage />
              </SuspensedView>
            }
          />

          <Route
            path="completed-orders/*"
            element={
              <SuspensedView>
                <OrderPage />
              </SuspensedView>
            }
          />

          <Route
            path="expenses/*"
            element={
              <SuspensedView>
                <ExpensePage />
              </SuspensedView>
            }
          />

          <Route
            path="products/*"
            element={
              <SuspensedView>
                <ProductPage />
              </SuspensedView>
            }
          />

          <Route
            path="dashboard/*"
            element={
              <SuspensedView>
                <DashboardPage />
              </SuspensedView>
            }
          />

          <Route
            path="user/*"
            element={
              <SuspensedView>
                <ChangePasswordPage />
              </SuspensedView>
            }
          />
          {/* <Route
          path="profile/*"
          element={
            <SuspensedView>
              <UpdateProfilePage />
            </SuspensedView>
          }
        /> */}
          <Route path="*" element={<Navigate to="/products" />} />
        </Route>
      </Routes>
    </>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
